import {
  Component,
  HostListener,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
} from "@angular/core";
import { Router, NavigationStart } from "@angular/router";
import { Observable, Subject, from, BehaviorSubject, Subscription, iif, EMPTY, of, throwError } from "rxjs";
import { filter, catchError, skip, takeUntil, combineAll, reduce, combineLatest } from 'rxjs/operators';
import { Auth } from "./auth/_model/auth.model";
import { AuthDataService, LoaderService } from "./auth/_services";
import { Auth as AuthAWS, Hub } from "aws-amplify";
import { AuthStateService } from "./shared/_service/auth-state.service";
import { LazyLoadScriptService } from './shared/_service/lazy-load-script.service';
// import { TribeService } from "./shared/_service/tribe.service";
import { Store } from "@ngrx/store";
import { UserEntityService } from "./user/_services";
import { User } from "./user/_model";
import { sharedStore, sharedModels } from "astrakode-bc-library";
import { IDiagramError } from "astrakode-bc-composer";
import { EntityCacheDispatcher } from "@ngrx/data";
import {
  ConfirmationModalComponent,
  DialogService,
  DownloadService,
  LocalizationService,
  SharedModalComponent,
  ToasterService,
} from "astrakode-bc-library";
import { ProjectsService } from "./projects/_services";

import { environment } from "../environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { ProjectListComponent } from "./projects/project-list/project-list.component";
import { DeviceDetectorService } from "ngx-device-detector";
import { take, switchMap, tap } from "rxjs/operators";
import { StripeService } from "./shared/_service/stripe.service";
import * as userPlanAction from "./user-plan-store/_store/_actions/user-plan.action";
import { CrmService } from "./shared/_service/crm.service";
import { ProfileService } from "./user/_services/profile.service";
import { getCreateFreePlanSubscriptionParams, getStripeCustomerInfo } from "./user-plan-store/_store";
import { AuditService, AuditStatus, AuditType } from "./shared/_service/audit.service";

interface LoginTranslations {
  hello: string;
  welcome: string;
  intro: string;
  header: string;
  prev: string;
  next: string;
  done: string;
}

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  title = "AstrakodeBCPortal";
  currentRoute = window.location.href;

  translations: LoginTranslations = {
    prev: "Actions.Prev",
    next: "Actions.Next",
    done: "Actions.Done",
    hello: "Joyride.Welcome.Hello",
    welcome: "Joyride.Welcome.Welcome",
    intro: "Joyride.Welcome.Intro",
    header: "Joyride.PortalHeader.Header",
  };

  route: string;
  auth$: Observable<boolean>;
  // authSubject: any;
  currentUser: Auth;
  user$: Observable<User[]>;
  // posts: any;

  autoSave$: Observable<boolean>;
  lastSave$: Observable<Date>;

  headerLoading$: Observable<boolean>;
  errors$: Observable<{}[]>;
  tokenSubject: any;

  // project
  isEditingProjectPage$: Observable<boolean>;
  isPrivacy$: Observable<boolean>;
  isTerms$: Observable<boolean>;
  isLoginPage$: Observable<boolean>;
  isSignUpPage$: Observable<boolean>;
  isLoginOrSignUp: Observable<boolean>;
  isHomePage$: Observable<boolean>;
  isPlansPage$: Observable<boolean>;
  isProfilePage$: Observable<boolean>;
  isAccountPage$: Observable<boolean>;

  openedProjects$: Observable<sharedModels.OpenedProject[]>;
  openedWorkspace$: Observable<any>;
  url$: Observable<string>;

  isMenuOpen$: Observable<boolean>;

  projectName$: Observable<string>;
  branchId$: Observable<string>;
  downloadOS$: Observable<any>;
  // haveAcceptTerms$: Observable<boolean>;
  haveAcceptTerms: boolean = false;

  isMobile = false;

  public userSubject: Subject<any> = new Subject<any>();
  // public postsSubject: Subject<any> = new Subject<any>();
  public logoutSubject = new BehaviorSubject<any>(false);

  showUserProfileDetailsCompleted$: Observable<boolean>;
  protected destroy$ = new Subject<void>();
  userFromIDP: boolean = false;
  userAuthProvider: "Default" | "Google" | "Linkedin" = "Default";

  constructor(
    private store: Store<sharedStore.SharedStoreState>,
    private _router: Router,
    public authService: AuthStateService,
    private userService: UserEntityService,
    private entityCacheDispatcher: EntityCacheDispatcher,
    private projectsService: ProjectsService,
    private toasterService: ToasterService,
    private dialogService: DialogService,
    private downloadService: DownloadService,
    private authDataService: AuthDataService,
    private localizationService: LocalizationService,
    private translateService: TranslateService,
    public loaderService: LoaderService,
    private ref: ChangeDetectorRef,
    private projectListComponent: ProjectListComponent,
    private deviceService: DeviceDetectorService,
    private lazyLoadService: LazyLoadScriptService,
    private stripeService: StripeService,
    private crmService: CrmService,
    private profileService: ProfileService,
    private auditService: AuditService
  ) {


    // AuthAWS.currentAuthenticatedUser().then((user) => {
    //     this.lazyLoadService.loadProduktly().then(() => {
    //       // Script loaded successfully
    //       // You can now use the functions defined in the script
    //       window.onload = () => {
    //         window['Produktly'].identifyUser(
    //           user.attributes.email,
    //           {
    //             hubspotId: user.attributes['custom:crm_id'],
    //             stripeId: user.attributes['custom:stripe_id'],
    //             email: user.attributes.email,
    //             lastLoginDate: "???",
    //           }
    //         );
    //       };
    //     }).catch(error => {
    //       console.error('Error loading script', error);
    //     });
    //   });

    this.showUserProfileDetailsCompleted$ = this.store.select(sharedStore.isUserProfileDetailsCompleted);

    // if (deviceService.isMobile() || deviceService.isTablet()) {
    //   this.isMobile = true;
    // }


    localStorage.setItem("lang", this.translateService.getBrowserLang() || "enUS");

    this.route = window.location.pathname;
    this.auth$ = this.authService.isLoggedIn();
    // this.authSubject = this.authService.isLoggedInSubject();
    this.user$ = userService.entities$;

    from(AuthAWS.currentSession()).subscribe(
      (data: any) => {
        this.userFromIDP = data.idToken?.payload?.identities && data.idToken?.payload?.identities.length > 0;
        if (this.userFromIDP) {
          this.userAuthProvider = data.idToken?.payload?.identities[0].providerName;
          if (!this.authService.hasToken()) {
            this.authService.login();
          }
          this.authDataService.signinFromIDP(data.idToken.payload);
          this.store.dispatch(new sharedStore.UpdateIsLogging(true));
        }

        this.userService.getByKey(0).subscribe(
          (data) => {
            console.log("Logged in");
          },
          (error) => {
            console.error(error);
            throw { code: "NotAuthorizedException" };
          }
        );
      },
      (error) => {
        console.warn(error);
        if (error?.code == "NotAuthorizedException") {
          throw { code: "NotAuthorizedException" };
        }
        if (error == "No current user") {
          //user not logged-in. we need to redirect to the login page
          AuthAWS.signOut()
            .then((data) => {
              this.authService.logout();
              // this._router.navigate(["/login"], { replaceUrl: true });
            })
            .catch((err) => console.log(err));
        }
      }
    );
    this.user$.subscribe((state: any) => {
      const userAttributes = state[0];

      this.userSubject.next(userAttributes);

      if (userAttributes) {
        //const sessionLocale = window.localStorage.getItem("lang");
        //const locale = sessionLocale || userAttributes?.["custom:lang"];
        const locale = userAttributes?.["custom:lang"];

        this.localizationService.use(locale || "enUS");
        localStorage.setItem("lang", userAttributes?.["custom:lang"] || "enUS");
      }

    });

    this.tokenSubject = this.authDataService.getCurrentTokenSubject();

    if (
      window.location.pathname.match("login") ||
      this.route === "sign-up" ||
      this.route === "/"
    ) {
      if (localStorage.getItem("remember") === "false") {
        AuthAWS.configure({ storage: sessionStorage });
      }
      AuthAWS.currentAuthenticatedUser()
        .then((data) => {
          this._router.navigate(["/home"], { replaceUrl: true });
        })
        .catch((err) => {
          console.log(err);
        });
    }

    this.authService.isLogOutSubject.subscribe((logout) => {
      let temp = false;
      if (logout) {
        temp = true;
        this.authService.isLogOutAppSubject.subscribe((data) => {
          if (data && logout && temp) {
            temp = false;
            this.authService.logout();
            this.authService.isLogOutAppSubject.next(false);
            this.authService.isLogOutSubject.next(false);
          }
        });
        temp = false;
      }
    });
  }
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onDone() {
    // this.guideToursService.onDone();
  }

  onDoneNetCompOnBoarding() {
    // this.guideToursService.onDoneNetCompOnBoarding();
  }

  ngOnInit() {

    //Let keep the comment, it could be usefull
    // Hub.listen('auth', ({ payload: { event, data } }) => {
    //   console.log("HUB EVENT: ", event);
    //   switch (event) {
    //     case 'signIn':
    //       // L'utente ha eseguito l'accesso
    //       console.log("signIn");
    //       break;
    //     case 'signOut':
    //       // L'utente ha eseguito il logout
    //       console.log("signOut");
    //       break;
    //     case 'tokenRefresh':
    //       // I token sono stati aggiornati
    //       console.log("tokenRefresh");
    //       break;
    //     case 'tokenRefresh_failure':
    //       // Il tentativo di aggiornare i token è fallito, potenzialmente a causa della scadenza della sessione
    //       console.log("tokenRefresh_failure");
    //       break;
    //     case 'signIn_failure':
    //     case 'signUp_failure':
    //       console.log("signIn_failure or signUp_failure");
    //       // Gestisci gli errori di accesso o registrazione
    //       break;
    //     default:
    //       break;
    //   }
    // });

    // this.store.dispatch(new sharedStore.UpdateBEWithUserPlan(false));

    this.auth$.pipe(filter(isLoggedIn => isLoggedIn)).subscribe(isLoggedIn => {
      if (isLoggedIn) {
        const auth = JSON.parse(localStorage.getItem("Auth"));
        const userCognitoId = localStorage.getItem("userCognitoId");
        AuthAWS.currentAuthenticatedUser().then((user) => {
          let userLocalStorage = {
            userId: user.attributes.sub,
            username: user.attributes.email,
            name: user.attributes.name,
            email: user.attributes.email,
            org: user.attributes["custom:company"],
          };
          localStorage.setItem("Auth", JSON.stringify(userLocalStorage));
          window.onload = () => {
            window['Produktly'].identifyUser(
              user.attributes.email,
              {
                hubspotId: user.attributes['custom:crm_id'],
                stripeId: user.attributes['custom:stripe_id'],
                email: user.attributes.email,
                lastLoginDate: "???",
              }
            );
          };

          this.store.select(sharedStore.isGetPairsCallSuccess).pipe(
            takeUntil(this.destroy$),
            filter(isGetPairsCallSuccess => isGetPairsCallSuccess)
          ).subscribe(isGetPairsCallSuccess => {
            this.store.dispatch(new sharedStore.GetPairsCallReset());
            this.store.dispatch(new sharedStore.GetWorkspaces());
            this.store.dispatch(new userPlanAction.GetStripeCustomerInvoicesBillingpage());
            this.store.dispatch(new sharedStore.GetUserServicesList());
          });

          let userAttributes = user.attributes;

          if (userAttributes) {
            const crmId = userAttributes["custom:crm_id"];
            const stripeId = userAttributes["custom:stripe_id"];
            const defWorkspaceCreated = userAttributes["custom:defWorkspaceCreated"];
            let newStripeId = stripeId;

            if (userCognitoId != userAttributes.sub) {
              localStorage.setItem("isBEUpdatedWithUserPlan", "false");
              localStorage.setItem("userCognitoId", userAttributes.sub);
            }

            // let isBEUpdatedWithUserPlan = localStorage.getItem("isBEUpdatedWithUserPlan");
            // if (isBEUpdatedWithUserPlan == "true") {
            //   //The BE is already been updated calling the getPairs, so we need call the getworkspace...
            //   this.store.dispatch(new sharedStore.GetPairsCallSuccess());
            // }

            this.store.select(sharedStore.isLogging).pipe(take(1)).subscribe((isLogging: boolean) => {
              if (isLogging) {
                //only if the user is logging and not on the page reload
                let crmProperties: { "label": string, "value": any; }[] = [
                  {
                    "label": "akb_confirmed",
                    "value": true
                  },
                  {
                    "label": "hs_legal_basis",
                    "value": "Legitimate interest – prospect/lead"
                  }
                ];
                let userExtraDetails = localStorage.getItem("userExtraDetails");
                if (userExtraDetails) {
                  userExtraDetails = JSON.parse(userExtraDetails);
                  crmProperties.push({ "label": "akb_utm_campaign", "value": userExtraDetails['attributes']['custom:akb_utm_campaign'] });
                  crmProperties.push({ "label": "akb_utm_medium", "value": userExtraDetails['attributes']['custom:akb_utm_medium'] });
                  crmProperties.push({ "label": "akb_utm_source", "value": userExtraDetails['attributes']['custom:akb_utm_source'] });
                  crmProperties.push({ "label": "akb_keyword", "value": userExtraDetails['attributes']['custom:akb_keyword'] });
                  crmProperties.push({ "label": "source", "value": userExtraDetails['attributes']['custom:environmentType'] });
                  crmProperties.push({ "label": "country", "value": userExtraDetails['attributes']['custom:regCountryName'] });
                  crmProperties.push({ "label": "device", "value": userExtraDetails['attributes']['custom:regDevice'] });
                }
                let updateCRM: any = {
                  caller: "onLogin",
                  customerEmail: userAttributes["email"],
                  action: "login",
                  properties: crmProperties
                };
                if (crmId) {
                  //the user is already confirmed, is doing just a login
                  updateCRM = {
                    caller: "onLogin",
                    customerEmail: userAttributes["email"],
                    action: "login",
                    properties: []
                  };
                } else {
                  //is logging for the first time, it means that confirmed the registration
                  //we need to add a tag for Google Tag Manager in order to recognize that a user confirmed the registration
                  const div = document.createElement("div");
                  const node = document.createTextNode(this.userAuthProvider);
                  div.appendChild(node);
                  div.setAttribute("id", "userConfirmation");
                  const element = document.getElementById("googleAnalyticsContainer");
                  element.appendChild(div);

                  // from(this.authDataService.getUserGeolocation()).pipe(
                  //   takeUntil(this.destroy$)
                  // ).subscribe(geolocationResp => {
                  //   updateCRM.properties["custom:regCountryName"] = geolocationResp["country_name"];
                  //   updateCRM.properties["custom:regCountryCode"] = geolocationResp["country_code"];
                  //   updateCRM.properties["custom:regRegion"] = geolocationResp["region"];
                  //   updateCRM.properties["custom:regIP"] = geolocationResp["ip"];
                  // });
                }
                let globalGeolocationResp;
                let device;
                //in crm will be update the last activity date and if not exist will create the crmId property in cognito
                from(this.authDataService.getUserGeolocation("OnLogin")).pipe(
                  take(1),
                  tap(geolocationResp => {
                    if (geolocationResp && geolocationResp["country_name"]) {
                      globalGeolocationResp = geolocationResp;
                      device = "Desktop";

                      if (this.deviceService.isMobile()) {
                        device = "Mobile";
                      } else if (this.deviceService.isTablet()) {
                        device = "Tablet";
                      }

                      crmProperties.push({ "label": "country", "value": geolocationResp["country_name"] });
                      crmProperties.push({ "label": "device", "value": device });

                      updateCRM.properties = updateCRM.properties.concat(crmProperties);
                    }
                  }),
                  switchMap(resp => this.crmService.updateCRM(updateCRM)),
                  tap((updateCrmResp: any) => {
                    userAttributes = undefined;
                    if (!crmId) {
                      userAttributes = {
                        "custom:crm_id": updateCrmResp['id']
                      };
                    }
                    if (this.userFromIDP) {
                      if (userExtraDetails) {
                        userAttributes = { ...userAttributes, ...userExtraDetails['attributes'] };
                      } else if (!crmId) {
                        userAttributes["custom:regCountryName"] = globalGeolocationResp["country_name"];
                        userAttributes["custom:regCountryCode"] = globalGeolocationResp["country_code"];
                        userAttributes["custom:regRegion"] = globalGeolocationResp["region"];
                        userAttributes["custom:regIP"] = globalGeolocationResp["ip"];
                        userAttributes["custom:regDevice"] = device;
                        userAttributes["custom:environmentType"] = environment.TYPE;
                      }
                    }
                  }),
                  switchMap(updateCrmResp => {
                    return iif(() => !crmId, this.profileService.updateUserInfo(userAttributes));
                  }),
                  switchMap(createWorkResp => iif(() => !crmId, this.userService.getByKey(0)))
                ).subscribe(resp => {
                  //we need to cleanup removing the previous html tag created used by Google Tag Manager
                  const element = document.getElementById("userConfirmation");
                  element.remove();
                  if (!crmId && this.userFromIDP) {
                    //in case of a first login done with a Identity provider we need to update the audit
                    this.auditService.generateAudit(
                      AuditStatus.Success,
                      AuditType.postSignup,
                      JSON.stringify({
                        event: "AK_POST_SIGNUP",
                        eventDescription: "It is saved during the first login with an Identiy Provider. It is used to as user confirmation.",
                        userEmail: userAttributes["email"],
                        techSpec: {
                          serviceName: "app.component",
                          serviceInput: {
                            ...userAttributes
                          },
                        }
                      })
                    );
                  }
                });

                this.store.dispatch(new sharedStore.UpdateIsLogging(false));
              }
              this.store.dispatch(new sharedStore.GetServices());
              this.store.dispatch(new sharedStore.GetUserServicesList());
            });

            this.store.dispatch(new userPlanAction.UpdateStripeId(""));

            this.store.dispatch(new userPlanAction.GetStripePricesListOnAppLoad());
            this.store.dispatch(new userPlanAction.GetStripeTaxidTypesOnAppLoad());

            this.store.select(getStripeCustomerInfo).pipe(
              takeUntil(this.destroy$),
              filter((customerInfo: any) => Object.keys(customerInfo).length > 0),
            ).subscribe((customerInfo: any) => {
              this.store.dispatch(new userPlanAction.GetStripeCustomerTaxidOnAppLoad(customerInfo.id));
            });

            //if the user does not have any subscription, we need to activate the free plan
            this.store.select(getCreateFreePlanSubscriptionParams).pipe(
              takeUntil(this.destroy$),
              filter(result => {
                return !result.hasUserGotPlan && result.customerId && result.items.length > 0 && result.items[0].price;
              }),
              tap(result => {
                console.log(result);
              })
            ).subscribe((result: { customerId: string, items: { price: string; }[]; }) => {
              this.store.dispatch(new userPlanAction.CreateFreePlanSubscriptionOnAppLoad({ ...result }));
            });

            if (stripeId) {
              //a stripeId already exist on cognito, it means that the user has been already created in Stripe

              // isBEUpdatedWithUserPlan = localStorage.getItem("isBEUpdatedWithUserPlan");
              //commented because now everytime that a customer apply for a plan (free or not) the GetPairCall is called
              // if (isBEUpdatedWithUserPlan == "false") {
              //   this.store.dispatch(new sharedStore.GetPairsCall(stripeId));
              // }

              this.store.dispatch(new sharedStore.GetPairsCallSuccess());
              this.store.dispatch(new userPlanAction.UpdateStripeId(stripeId));
              this.store.dispatch(new userPlanAction.GetStripeCustomerInfoOnAppLoad());

            } else {
              //the user will be created in stripe. This part should be done only at the first login.
              from(this.stripeService.createCustomer(user)).pipe(
                take(1),
                tap((createCustomerResp: any) => newStripeId = createCustomerResp.id),
                tap(createCustomerResp => {
                  //the following is commented because now the subscription is not created in the
                  //createCustomer lambda but after the effect triggered by the free plan subscrition creation
                  // this.store.dispatch(new sharedStore.GetPairsCallOnPageLoad(newStripeId));
                  this.store.dispatch(new userPlanAction.UpdateStripeId(newStripeId));
                }),
                switchMap(updateCrmResp => {
                  return this.profileService.updateUserInfo({ "custom:stripe_id": newStripeId });
                })
              ).subscribe(resp => this.store.dispatch(new userPlanAction.GetStripeCustomerInfoOnAppLoad()));
            }


            if (defWorkspaceCreated != "true") {
              //if not done yet, the default workspace will be created
              const defaultWorkspaceData = {
                name: "Default Workspace",
                description: "The default workspace.",
                username: userAttributes["email"]
              };

              this.projectsService.createWorkspace(defaultWorkspaceData).pipe(
                take(1),
                tap(resp => {
                  userAttributes = { "custom:defWorkspaceCreated": "true" };
                }),
                switchMap(createWorkResp => {
                  return this.profileService.updateUserInfo(userAttributes);
                })
              ).subscribe();
            }
          }

        }).catch(err => {
          console.warn(err);
        });
      }
    });

    this.haveAcceptTerms = localStorage.getItem("terms") === 'true';
    //this.haveAcceptTerms$ = this.store.select(getAcceptTerms);

    this.autoSave$ = this.store.select(sharedStore.getAutoSave);
    this.lastSave$ = this.store.select(sharedStore.getLastSave);

    this.headerLoading$ = this.store.select(sharedStore.getHeaderLoading);
    this.errors$ = this.store.select(sharedStore.getErrors);

    this.isTerms$ = this.store.select(sharedStore.getIsTerms);
    this.isPrivacy$ = this.store.select(sharedStore.getIsPrivacy);
    this.isLoginPage$ = this.store.select(sharedStore.getIsLogin);
    this.isSignUpPage$ = this.store.select(sharedStore.getIsSignUp);
    this.isLoginOrSignUp = this.store.select(sharedStore.getIsSignUpOrLogin);

    this.isHomePage$ = this.store.select(sharedStore.getIsHome);
    this.isPlansPage$ = this.store.select(sharedStore.getIsPlans);
    this.isProfilePage$ = this.store.select(sharedStore.getProfile);
    this.isAccountPage$ = this.store.select(sharedStore.getAccount);
    this.isEditingProjectPage$ = this.store.select(sharedStore.getIsEditingProject);

    this.openedProjects$ = this.store.select(sharedStore.getOpenedProjects);
    this.openedWorkspace$ = this.store.select(sharedStore.getOpenedWorkspace);
    this.url$ = this.store.select(sharedStore.getUrl);

    this.isMenuOpen$ = this.store.select(sharedStore.getIsMenuOpen);

    this.projectName$ = this.store.select(sharedStore.getProjectName);
    this.branchId$ = this.store.select(sharedStore.getBranchId);

    this._router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.routerChangeMethod(event.url);
      }
    });
  }

  ngAfterContentChecked() {
    this.ref.detectChanges();
  }

  routerChangeMethod(url) {
    // Hide captcha everywhere:
    document.documentElement.style.setProperty("--show-captcha", "0");
  }

  /**
   * Sends the user to the login page and deletes project and user entities from the storage
   *
   * @memberof AppComponent
   */
  goToLoginPage() {
    this.authService.logout();
  }
  // goToLoginPage() {
  //   let temp = true;
  //   this.loaderService.isLoading.subscribe((result) => {
  //     if (temp) {
  //       temp = false;
  //       if (!result) {
  //         this._router.navigate(["/login"]).then((result) => {
  //           if (result) {
  //             AuthAWS.signOut().then(
  //               (data) => {
  //                 this.authService.logout();
  //                 this.entityCacheDispatcher.clearCollections(["Project"]);
  //                 this.entityCacheDispatcher.clearCollections(["User"]);
  //                 this.store.dispatch(new Logout());
  //               },
  //               (error) => {
  //                 //this.toasterService.error(error);
  //                 console.error("SignOut Error during the goToLoginPage: ", error);
  //               }
  //             );
  //           } else {
  //             this.authService.isLogOutAppSubject.next(true);
  //           }
  //         });
  //       } else {
  //         this.translateService
  //           .get("ErrorMessages.ActivePetition")
  //           .subscribe((data: string) => {
  //             this.toasterService.error(data);
  //           });
  //       }
  //     }
  //   });
  // }
  /**
   * Sends the user to the profile page
   *
   * @memberof AppComponent
   */
  goToProfile() {
    this._router.navigate(["/user/edit"]);
  }

  /**
   * Sends the user to the my subscriptions page
   *
   * @memberof AppComponent
   */
  goToMySubscriptions() {
    this._router.navigate(["/user/subscriptions"]);
  }

  updateInfo() { }

  /**
   * Checks what action should be launched
   *
   * @param {{ key: string; data?: any }} action
   * @memberof AppComponent
   */
  onActionFired(action: { key: string; data?: any; }) {
    this.store.dispatch(new sharedStore.HeaderAction(action));

    if (action.key === "onShare") {
      this.shareProject();
    }
  }

  onToggleMenu() {
    this.store.dispatch(new sharedStore.ToggleMenu());
  }

  acceptTerms(action: { acceptTerms: boolean; }) {
    this.haveAcceptTerms = action.acceptTerms;
    localStorage.setItem("terms", action.acceptTerms ? "true" : "false");
    // this.store.dispatch(new AcceptTerms(action.acceptTerms));
  }

  // to uncomment when a Tribe will be replaced
  //postAlertClick(error: IDiagramError) {
  //   this.store.dispatch(new FocusOnError(error));
  // }

  /**
   * Opens the share project dialogs
   *
   * @memberof AppComponent
   */
  shareProject() {
    let temp = true;
    this.store.select(sharedStore.getBranch).pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if (temp && data) {
        let id = data.uuid;
        let share = true;
        temp = false;
        this.dialogService.open(SharedModalComponent, { class: 'form-dialog' }).subscribe((data) => {
          this.user$.subscribe((user: any) => {
            if (data) {
              let formData = data;
              let sharedData = {
                uuid: id,
                username: data.email,
              };
              if (share) {
                this.projectsService.shareProject(sharedData).pipe(take(1)).subscribe(
                  (data: any) => {
                    if (data) {
                      if (!data.info) {
                        //data.context.userName is the email of who is sharing
                        //sharedData.username is the email of to whom the project is shared
                        let crmShare = {
                          email: data.context.userName,
                          user: user[0].name,
                          message: formData.message,
                          projectshareEmail: sharedData.username,
                          projectType: data.project.type == "1" ? "network" : "logic",
                          projectId: data.project.id
                        };
                        this.projectsService
                          .shareProjectCRM(crmShare)
                          .subscribe(
                            (data) => {
                              share = false;
                              this.translateService
                                .get("SuccessMessages.ProjectShared")
                                .subscribe((data: string) => {
                                  this.toasterService.success(data);
                                });
                            },
                            (error) => {
                              share = false;
                              this.translateService
                                .get("ErrorMessages.ProjectShared")
                                .subscribe((data: string) => {
                                  this.toasterService.error(data);
                                });
                            }
                          );
                      } else {
                        share = false;
                        this.translateService
                          .get("ErrorMessages.ProjectShared")
                          .subscribe((data: string) => {
                            this.toasterService.error(data);
                          });
                      }
                    } else {
                      share = false;
                      this.translateService
                        .get("ErrorMessages.ProjectShared")
                        .subscribe((data: string) => {
                          this.toasterService.error(data);
                        });
                    }
                  },
                  (error) => {
                    share = false;
                    this.translateService
                      .get("ErrorMessages.ProjectShared")
                      .subscribe((data: string) => {
                        this.toasterService.error(data);
                      });
                  }
                );
              }
            }
          });
        });
      }
    });
  }

  onHelp(type: number) {
    this.onActionFired({
      key: "onHelp",
    });
  }

  openProjectDialog(project) {
    this.projectListComponent.openProjectDialog(project);
  }

}
